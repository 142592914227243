import { SAVE_INTERVIEW_REQUEST } from "redux/constants";
export const name = "saveRequest";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    saveRequestResults: [],
    closeRequestResults: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getSaveRequestResults: (state) => state[name]?.saveRequestResults ? state[name]?.saveRequestResults : "",
    getCloseRequestResults: (state) => state[name]?.closeRequestResults ? state[name]?.closeRequestResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            };
        case SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                saveRequestResults: action.payload
            };
            case SAVE_INTERVIEW_REQUEST.API_CLOSE_INTERVIEW_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                closeRequestResults: action.payload
            };
        default:
            return state;
    }
}