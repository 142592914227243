import React, { useState, useRef, useEffect, useContext } from "react";
import { Tooltip } from 'react-tooltip';
import { Label, Image } from 'semantic-ui-react';

import DownArrow from "assets/images/down_arrow.svg";
import CrossIcon from "assets/images/cross_icon.svg";
import WarningIcon from "assets/images/popup_warning.svg";
import InfoIcon from "assets/images/info-outline.svg";

import Checkbox from "components/shared/Checkbox";
import { FormContext } from "context/form-context";

import 'semantic-ui-css/semantic.min.css';
import './Provider.scss';

export const Provider = ({ handleProviderData, formErrorValid, anticipatedInterviewsCount, isSurveyForm, onFocusOutHandler, fieldId }) => {
    const popupRef = useRef(null);

    const dropdownOptions = useContext(FormContext).dropdownData;
    const formContext = useContext(FormContext);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isRemovingItem, setIsRemovingItem] = useState(false);
    const [outsideClickFlag, setOutsideClickFlag] = useState(false);
    const [initialleftSecPreferredProviders, setInitialLeftSecPreferredProviders] = useState([]);
    const [initialleftSecTier2, setInitialleftSecTier2] = useState([]);
    const [rightSecRegionalProviders, setrightSecRegionalProviders] = useState([]);
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [disabledItems, setDisabledItems] = useState([]);

    useEffect(() => {
        let preferredProvidersData = [];
        let tier2ProvidersData = [];
        let regionalProvidersData = [];
        if (dropdownOptions.providers) {
            dropdownOptions.providers.forEach((item) => {
                if ((isSurveyForm && item.forSurvey) || (!isSurveyForm)) {
                    if (item.providerType == "Global_Provider") {
                        preferredProvidersData.push(item);
                    }
                    if (item.providerType == "Tier2_Provider") {
                        tier2ProvidersData.push(item);
                    }
                    if (item.providerType == "Regional_Provider") {
                        regionalProvidersData.push(item);
                    }
                }
            });
        }
        setInitialLeftSecPreferredProviders(preferredProvidersData);
        setInitialleftSecTier2(tier2ProvidersData);
        setrightSecRegionalProviders(regionalProvidersData);
    }, [dropdownOptions.providers]);

    useEffect(() => {
        if (formContext.databaseValues['providers']?.length > 0 && dropdownOptions.providers) {
            setDisabledItems((usedProviders) => {
                const providerItems = [...usedProviders];
                formContext.databaseValues['providers'].forEach((item) => {
                    const recordIndex = dropdownOptions.providers.findIndex(p => p.id == item.id);
                    const duplicateIndex = providerItems.findIndex(p => p.name == dropdownOptions.providers[recordIndex].providerName);
                    if (recordIndex > -1 && duplicateIndex == -1) {
                        providerItems.push({ name: dropdownOptions.providers[recordIndex].providerName, email: item.researchManagerEmail });
                    }
                });
                return providerItems;
            });
        }
    }, [formContext.databaseValues, dropdownOptions.providers]);

    useEffect(() => {
        if (outsideClickFlag) {
            onFocusOutHandler(selectedCheckBoxes);
            setOutsideClickFlag(false);
        }
    }, [outsideClickFlag, selectedCheckBoxes]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
                setOutsideClickFlag(true);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('arrow--img') && !event.target.classList.contains('dropdown--textbox')) {
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [isRemovingItem]);

    useEffect(() => {
        renderSelectedCheckBoxes();
    }, [selectedCheckBoxes]);

    const handleRemoveItem = (incomingItem) => {
        const updatedItems = selectedCheckBoxes.filter(selectedItem => selectedItem !== incomingItem);
        setSelectedCheckBoxes(updatedItems);
        setIsRemovingItem(true);
        setIsPopupOpen(false);
        handleProviderData(updatedItems, selectedCheckBoxes);
    };

    const handleCheckBoxChange = (event, data) => {
        let prevdata = selectedCheckBoxes; // Initialize prevdata
        let updatedSelectedCheckBoxes;
        if (data.checked) {
            updatedSelectedCheckBoxes = [...selectedCheckBoxes, data?.label]
            setSelectedCheckBoxes(prev => [...prev, data?.label]);
        } else {
            updatedSelectedCheckBoxes = selectedCheckBoxes.filter(item => item !== data?.label);
            setSelectedCheckBoxes(prev => prev.filter(item => item !== data?.label));
        }
        handleProviderData(updatedSelectedCheckBoxes, prevdata);
    }


    const renderSelectedCheckBoxes = () => {
        return selectedCheckBoxes.map((item, index) => (
            <Label key={index}>
                {item}
                <Image src={CrossIcon} alt="crossIcon"
                    onClick={() => handleRemoveItem(item)}
                />
            </Label>
        ));
    }

    return (
        <div className="provider__wrap width70">
            <div className="provider__selbody">
                <div className="input__wrapper">
                    <input type='text' name={fieldId}
                        placeholder={selectedCheckBoxes?.length > 0 ? "" : "Select providers to send the request to"}
                        className={` dropdown--textbox input-fields field-border ${!formErrorValid ? 'error-input-fields form-control is-invalid field-error' : 'provider-input-fields'}`}
                        htmlFor="providersData" onClick={(event) => {
                            event.stopPropagation();
                            setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                        }}
                        readOnly ></input>
                    <div className="selected--checkboxes">{renderSelectedCheckBoxes()}</div>
                    <Image className="arrow--img dropdown-img img-top-margin" src={DownArrow} alt="DownArrow" onClick={(event) => {
                        event.stopPropagation();
                        setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                    }
                    } />
                </div>

                {anticipatedInterviewsCount < 5 && anticipatedInterviewsCount !== null && anticipatedInterviewsCount !== ''
                    &&
                    <div className="provider__popuperror">
                        <Image className="dropdown-img" src={WarningIcon} alt="WarningIcon"
                        />
                        <div className="error--msgtext">If conducting fewer than 5 interviews, limit your selection to 3 providers for optimal diversity.</div>
                    </div>
                }
                {isPopupOpen && (
                    <div className={anticipatedInterviewsCount < 5 ? "top45 provider__popup" : "top98 provider__popup"} ref={popupRef}>
                        <>
                            <div className="provider__popupbody">
                                <div className={!isSurveyForm ? "popup__bodyleftsection" : "popup__bodyleftsection-withoutRegionalProviders popup__bodyleftsection"}>
                                    <div className="popup__bodyleftsection-childfirst">
                                        <div className="popup--subhead">Global Preferred Providers</div>
                                        <div className="popup__bodyleftsection-childfirst-body">{
                                            initialleftSecPreferredProviders.length > 0 &&
                                            initialleftSecPreferredProviders.map((data, index) => (
                                                <Checkbox className="een-checkbox" label={data.providerName} onChange={handleCheckBoxChange} checked={selectedCheckBoxes.includes(data.providerName)} key={index} disabled={disabledItems.findIndex((item) => item.name == data.providerName) > -1} />
                                            ))
                                        }</div>
                                    </div>
                                    <div className="popup__body-leftsection-childsec">
                                        <div className="popup--subhead">Global Tier 2 Providers</div>
                                        <div className="popup__body-leftsection-tier2-body">{
                                            initialleftSecTier2.length > 0 &&
                                            initialleftSecTier2.map((data, index) => (
                                                <Checkbox className="een-checkbox" label={data.providerName} onChange={handleCheckBoxChange} checked={selectedCheckBoxes.includes(data.providerName)} key={index} disabled={disabledItems.findIndex((item) => item.name == data.providerName) > -1} />
                                            ))
                                        }</div>
                                    </div>
                                </div>
                                {
                                    !isSurveyForm &&
                                    <div className="popup__bodyRrightsection">
                                        <div className="popup--subhead">
                                            Regional Providers
                                            <Tooltip anchorSelect=".tooltip-regional" place="bottom-end" effect='solid' multiline={true} className="small-tooltip">
                                                If you select Regional providers, you will receive expert profiles on email outside the External Expert Hub.
                                            </Tooltip>
                                            <a className="tooltip-regional"><Image className="info-img" src={InfoIcon} alt="Info icon" /></a>
                                        </div>
                                        <div className="popup__bodyRrightsection-regionalProviders-body">{
                                            rightSecRegionalProviders.length > 0 &&
                                            rightSecRegionalProviders.map((data, index) => (
                                                <Checkbox className="een-checkbox" label={data.providerName} onChange={handleCheckBoxChange} checked={selectedCheckBoxes.includes(data.providerName)} key={index} disabled={disabledItems.findIndex((item) => item.name == data.providerName) > -1} />
                                            ))
                                        }</div>
                                    </div>

                                }
                            </div>
                        </>
                    </div>
                )}
            </div>
            {formContext.formView == 'edit' &&
                <div className="edit-caseteam">
                    {disabledItems.map((type, index) =>
                        <div className="saved-case-team-member" key={index}>
                            <span className={type.name}>{type.name}</span>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}