import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';

import { getUserId } from "utils/auth/auth";
import { EXPERT_INTERVIEW_FIELDS, EXPERT_SURVEY_FIELDS } from 'assets/static_data/form-fields';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { FormContext } from "context/form-context";
import { getStaffId, initiateSubmitClickAnalytics } from '../../analytics.js';

import { FormFields } from "components/shared/FormFields";
import { AlertPopup } from "components/shared/AlertPopup";

import { getPeopleAPIData } from "redux/actions/peopleresults/peopleresults.actions";
import { getSaveInterviewAPIData } from 'redux/actions/saveInterviewRequest/saveInterviewRequest.actions';
import { getExpertTypeAPIData } from "redux/actions/expertTypes/expertTypes.actions";
import { getRequestTypeAPIData } from "redux/actions/requestType/requestType.actions";
import { getDeleteFileAPIData } from "redux/actions/deleteAttachment/deleteAttachment.actions";
import { getRenameFileAPIData } from "redux/actions/renameAttachment/renameAttachment.actions";
import { getExternalNetworkAPIData } from "redux/actions/externalNetwork/externalNetwork.actions";
import { uploadStart } from "redux/actions/uploadFile/uploadFile.action";

import { selectors as peopleSelector } from "../../redux/reducers/peopleresults/peopleresults_reducer";
import { selectors as saveInterviewRequestSelector } from "redux/reducers/saveRequest/saveRequest_reducer";
import { selectors as expertTypeSelector } from "redux/reducers/expertTypes/expertTypes_reducer";
import { selectors as requestTypeSelector } from "redux/reducers/requestType/requestType_reducer";
import { selectors as uploadFileSelector } from "redux/reducers/uploadFile/uploadFile_reducer";
import { selectors as deleteFileSelector } from "redux/reducers/deleteAttachment/deleteAttachment_reducer";
import { selectors as renameFileSelector } from "redux/reducers/renameAttachment/renameAttachment_reducer";
import { selectors as externalNetworkSelector } from "redux/reducers/externalNetwork/externalNetwork_reducer";

import infoIcon from '../../assets/images/info_icon.png';
import flag from '../../assets/images/flag-white.svg';

import './ExpertInterviewsForm.scss';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export const ExpertInterviewsForm = ({ isSurveyForm, freshView, editData }) => {

    const formFieldsData = isSurveyForm ? EXPERT_SURVEY_FIELDS : EXPERT_INTERVIEW_FIELDS;
    const requestFormRef = useRef();
    const dispatch = useDispatch();

    const expertData = useSelector(expertTypeSelector.getexpertTypesResults);
    const requestTypeData = useSelector(requestTypeSelector.getRequestTypesResults);
    const progressData = useSelector(uploadFileSelector.getFileName);
    const externalNetworkData = useSelector(externalNetworkSelector.getExternalNetworkResults);
    const deleteFileStatus = useSelector(deleteFileSelector.getDeleteFileResults);
    const renameFileStatus = useSelector(renameFileSelector.getRenameFileResults);
    const peopleData = useSelector(peopleSelector.getPeopleResults);
    const loading = useSelector(saveInterviewRequestSelector?.getLoading);
    const saveRequest = useSelector(saveInterviewRequestSelector.getSaveRequestResults);


    const formHandlers = useContext(FormContext);
    const invalidFields = formHandlers.invalidFields;

    const [oldProvidersData, setOldProvidersData] = useState([]);
    const [attachmentData, setAttachmentData] = useState([]);
    const [uploadFileTempData, setUploadFileTempData] = useState({});
    const [uploadFolder, setUploadFolder] = useState();
    const [uploadProgressValue, setUploadProgressValue] = useState(10);
    const [uploadFileID, setUploadFileID] = useState();
    const [selectedFileRecord, setSelectedFileRecord] = useState(null);
    const [alert, setAlert] = useState({});
    const [phoneFieldValidityFlag, setPhoneFieldValidityFlag] = useState(true);
    const [saveAPICompletedFlag, setSaveAPICompleted] = useState(freshView);
    const [submitBtnClickedFlag, setSubmitBtnClicked] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getRequestTypeAPIData());
        dispatch(getExternalNetworkAPIData());
        dispatch(getExpertTypeAPIData());
        fetchUserDetails();
    }, []);

    useEffect(() => {
        if (progressData && uploadFileTempData?.folderName) {
            formHandlers.toggleFormValues('attachmentsFolderName', uploadFileTempData.folderName);
            setUploadProgressValue(100);
            setAttachmentData((record) => {
                const updatedItems = [...record];
                const recordIndex = updatedItems.findIndex(file => file.fileName == progressData);
                if (recordIndex > -1) {
                    updatedItems[recordIndex].uploadProgress = 100;
                }
                return updatedItems;
            });
        }
    }, [progressData]);

    useEffect(() => {
        formHandlers.toggleDropdownData('expertType', expertData);
    }, [expertData]);

    useEffect(() => {
        if (requestTypeData?.length > 0) {
            formHandlers.toggleDropdownData('projectType', requestTypeData);
        }
    }, [requestTypeData]);

    useEffect(() => {
        if (externalNetworkData?.length > 0) {
            formHandlers.toggleDropdownData('providers', externalNetworkData);
        }
    }, [externalNetworkData]);

    useEffect(() => {
        if (uploadProgressValue < 95) {
            const min = uploadProgressValue;
            const max = 100;
            let rand = min + Math.floor(Math.random() * (max - min + 1));
            setAttachmentData((record) => {
                const updatedItems = [...record];
                const recordIndex = updatedItems.findIndex(file => file.fileName == uploadFileTempData.fileName);
                if (recordIndex > -1) {
                    if (updatedItems[recordIndex].downloadLink || updatedItems[recordIndex].uploadProgress == 100) {
                        rand = 100;
                    }
                    updatedItems[recordIndex].uploadProgress = rand;
                }
                setUploadProgressValue(rand);
                return updatedItems;
            });
        }
    }, [uploadProgressValue]);

    useEffect(() => {
        if (selectedFileRecord) {
            if (deleteFileStatus?.message && selectedFileRecord.event == 'delete') {
                setAttachmentData((record) => {
                    const updatedItems = [...record];
                    const filteredList = updatedItems.filter(item => item.fileName != selectedFileRecord.file.fileName);
                    setSelectedFileRecord(null);
                    if (!filteredList || filteredList?.length == 0) {
                        formHandlers.toggleFormValues('attachmentsFolderName', '');
                    }
                    return filteredList;
                });
            }
            if (renameFileStatus?.message && selectedFileRecord.event == 'rename' && selectedFileRecord.updatedName) {
                setAttachmentData((record) => {
                    const updatedItems = [...record];
                    const selectedFile = updatedItems.filter(item => item.fileName == selectedFileRecord.file.fileName);
                    if (selectedFile || selectedFile?.length > 0) {
                        const fileIndex = updatedItems.findIndex(ele => ele.fileName == selectedFile[0].fileName);
                        if (fileIndex > -1) {
                            const updatedName = selectedFileRecord.updatedName;
                            updatedItems[fileIndex].fileName = updatedName;
                        }
                    }
                    setSelectedFileRecord(null);
                    return updatedItems;
                });
            }
        }
    }, [deleteFileStatus, renameFileStatus, selectedFileRecord]);

    useEffect(() => {
        //handling form fields in case of editing request form.
        if (formHandlers.formView == 'edit') {
            //display uploaded files
            uploadSavedFiles();
            //error message for expired request end date
            const isEndDateExpired = (new Date(formHandlers.fieldsData.interviewEndDate) < new Date() && new Date(formHandlers.fieldsData.interviewEndDate).toDateString() != new Date().toDateString());
            const expertRecordIndex = formFieldsData.findIndex(item => item.id == "interviewEndDate");
            if (isEndDateExpired) {
                formFieldsData[expertRecordIndex].error = "Interview end date must be either today or a future date.";
                formHandlers.reCheckFieldStatus("interviewEndDate", true);
            } else {
                formFieldsData[expertRecordIndex].error = "Select interview end date.";
            }
            //setting Expert Type mandatory based on project type value
            if (formHandlers.fieldsData.projectType) {
                const expertRecordIndex = formFieldsData.findIndex(item => item.id == "expertType");
                formFieldsData[expertRecordIndex].isMandatory = formHandlers.fieldsData.projectType.includes('Diligence');
                formFieldsData[expertRecordIndex].error = formHandlers.fieldsData.projectType.includes('Diligence') ? "Please select an expert type/module." : '';
                formHandlers.reCheckFieldStatus("expertType", formHandlers.fieldsData.projectType.includes('Diligence'));
            }
        }
    }, [formHandlers.formView]);

    useEffect(() => {
        if (!saveAPICompletedFlag) {
            if (saveRequest?.error) {
                setSubmitBtnClicked(false);
                setAlert({
                    type: 'error',
                    requestId: 0,
                    requestType: isSurveyForm ? 'Survey' : 'Interview',
                    projectType: '',
                    show: true
                });
            }
            if (saveRequest?.interviewRequestId || saveRequest?.surveyRequestId) {
                setSubmitBtnClicked(false);
                setAlert({
                    type: 'success',
                    requestType: isSurveyForm ? 'Survey' : 'Interview',
                    requestId: isSurveyForm ? saveRequest.surveyRequestId : saveRequest.interviewRequestId,
                    projectType: saveRequest.projectType ? saveRequest.projectType : '',
                    show: true,
                    newRequest: formHandlers.formView == 'add'
                });
                dispatch(getSaveInterviewAPIData(null));
            }
        }
    }, [saveRequest, saveAPICompletedFlag]);

    useEffect(() => {
        if (editData) {
            formHandlers.toggleFormValues('formNotTouched', false);
            formHandlers.toggleFormView('edit', editData);
        } else {
            formHandlers.toggleFormView('add', {});
        }
    }, [editData, isSurveyForm]);

    useEffect(() => {
        peopleData[0]?.id ? formHandlers.toggleFormValues('originalRequestorHrid', peopleData[0].id) : '';
        if (formHandlers.formView != 'edit') {
            peopleData[0]?.preferredName ? formHandlers.toggleFormValues('requestorName', peopleData[0].preferredName) : '';
            peopleData[0]?.id ? formHandlers.toggleFormValues('hrId', peopleData[0].id) : '';
            peopleData[0]?.email ? formHandlers.toggleFormValues('requestor', peopleData[0].email) : '';
            if (!formHandlers.fieldsData['phoneNo']) {
                peopleData[0]?.phone ? formHandlers.toggleFormValues('phoneNo', peopleData[0].phone) : '';
            }
            peopleData[0]?.hostOfficeLocation?.name ? formHandlers.toggleFormValues('office', peopleData[0].hostOfficeLocation.name) : '';
            peopleData[0]?.hostOfficeLocation?.timezone ? formHandlers.toggleFormValues('timezone', peopleData[0].hostOfficeLocation.timezone) : '';
        }
    }, [peopleData, isSurveyForm]);

    const fetchUserDetails = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            getStaffId(resp[0]?.email);
        }
        dispatch(getPeopleAPIData(hrid));
    }

    const updateCaseTeamMembers = (memberSelected) => {
        const fieldIndex = formFieldsData.findIndex(item => item.id === 'caseTeam');
        const record = formFieldsData[fieldIndex];
        formHandlers.toggleFormValues('caseTeam', memberSelected, record.isMandatory);
    }

    const validateErrors = (event, fieldId, isCustom) => {
        if (formHandlers.fieldsData['formNotTouched']) {
            formHandlers.toggleFormValues('formNotTouched', false);
        }
        let value;
        let invalidValue = false;
        if (isCustom) {
            value = event;
        }
        else {
            value = event?.target?.value;
        }
        if ((fieldId === 'interviewAnticipatedCount' || fieldId === 'totalSurvey') && (value < 1 || value.includes('-') || value.includes('+'))) {
            value = '';
        }
        else if (!value) {
            value = formHandlers.fieldsData[fieldId];
        }
        if (fieldId.includes("End")) {
            invalidValue = (new Date(value) < new Date() && new Date(value).toDateString() != new Date().toDateString());
        }
        const fieldIndex = formFieldsData.findIndex(item => item.id === fieldId);
        const record = formFieldsData[fieldIndex];
        if ((!value || value?.length == 0 || invalidValue) && record.error) {
            record.isValid = false;
            formHandlers.logInvalidFields(fieldId);
        } else {
            record.isValid = true;
            formHandlers.markFieldValid(fieldId);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        const fieldIndex = formFieldsData.findIndex(item => item.id === name);
        const isMandatory = formFieldsData[fieldIndex].isMandatory;
        formHandlers.toggleFormValues(name, value, isMandatory);
    };

    const handleProviderData = (data, prevData, fieldId) => {
        setOldProvidersData(prevData);
        const fieldIndex = formFieldsData.findIndex(item => item.id === fieldId);
        const isMandatory = formFieldsData[fieldIndex].isMandatory;
        formHandlers.toggleFormValues(fieldId, data, isMandatory);
    }

    const handleSelManagers = (data, field) => {
        if (data) {
            const fieldIndex = formFieldsData.findIndex(item => item.id === field);
            const isMandatory = formFieldsData[fieldIndex].isMandatory;
            formHandlers.toggleFormValues(field, data, isMandatory);
            if (field == 'projectType' || field == 'expertType') {
                const projectTypeValue = field == 'projectType' ? data : formHandlers.fieldsData['projectType'];
                const expertRecordIndex = formFieldsData.findIndex(item => item.id == "expertType");
                if (expertRecordIndex > -1) {
                    formFieldsData[expertRecordIndex].isMandatory = projectTypeValue.includes('Diligence');
                    formFieldsData[expertRecordIndex].error = projectTypeValue.includes('Diligence') ? "Please select an expert type/module." : '';
                }
                if ((field != 'expertType' && (!formHandlers.fieldsData['expertType'] || formHandlers.fieldsData['expertType']?.length == 0))
                    || (field == 'expertType' && (!data || data?.length == 0))) {
                    formHandlers.reCheckFieldStatus("expertType", projectTypeValue.includes('Diligence'));
                }
            }
            if (field.includes('EndDate')) {
                if (data < new Date() && data.toDateString() != new Date().toDateString()) {
                    const expertRecordIndex = formFieldsData.findIndex(item => item.id == field);
                    if (expertRecordIndex > -1) {
                        formFieldsData[expertRecordIndex].error = "Interview end date must be either today or a future date.";
                    }
                }
            }
        } else {
            formHandlers.toggleFormValues(field, '');
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const handlePhoneChange = (data, flag) => {
        if (flag && data) {
            formHandlers.toggleFormValues('phoneNo', data);
        }
    }

    const uploadSavedFiles = () => {
        const randomFolderName = uploadFolder ? uploadFolder : randomFolderNameGenerator();
        if (!uploadFolder) {
            setUploadFolder(randomFolderName);
        }
        formHandlers.toggleFormValues('attachmentsFolderName', randomFolderName);
        const s3UploadedFiles = [];
        if (formHandlers.fieldsData?.attachmentsDetails?.length > 0) {
            formHandlers.fieldsData?.attachmentsDetails.forEach((file) => {
                let duplicateArrayEntry = s3UploadedFiles.filter(item => item.name == file.fileName);
                if (!duplicateArrayEntry || duplicateArrayEntry.length == 0) {
                    const savedFileData = {
                        id: s3UploadedFiles.length + 1,
                        name: file.fileName,
                        size: file.sizeInBytes,
                        url: file.url,
                        uploadedDate: file.uploadedDate
                    };
                    s3UploadedFiles.push(savedFileData);
                    formatAttachmentData(savedFileData, randomFolderName);
                    setUploadProgressValue(100);
                }
            });
        }
    }
    const formatAttachmentData = (data, folderName) => {
        const currentDateArray = new Date().toDateString().split(' ');
        const uploadingDate = currentDateArray[2] + ' ' + currentDateArray[1] + ', ' + currentDateArray[3];
        var sizeInMB = (data.size / (1024 * 1024)).toFixed(2);
        const fileName = data.name;
        const fileExt = fileName.slice(fileName.lastIndexOf('.') + 1, fileName.length);

        const formatedFileData = {
            fileId: data.id ? data.id : attachmentData.length + 1,
            fileName: data.name,
            fileSize: sizeInMB,
            folderName: folderName,
            sizeExceeded: false,
            fileExt: fileExt,
            uploadProgress: data.uploadedDate ? 100 : 10,
            downloadLink: data.url ? data.url : '',
            uploadDate: data.uploadedDate ? data.uploadedDate : uploadingDate
        };

        setUploadProgressValue(formatedFileData.uploadProgress);
        setUploadFileID(formatedFileData.fileId);

        let totalSize = 0;
        attachmentData.forEach((item) => {
            if (!item.sizeExceeded) {
                totalSize = totalSize + Number(item.fileSize);
            }
        });
        totalSize = totalSize + Number(sizeInMB);
        formatedFileData.sizeExceeded = totalSize > 150;
        if (totalSize > 150) {
            if (Number(sizeInMB) > 150) {
                formatedFileData.sizeError = 'The file is too large to upload.';
            } else {
                formatedFileData.sizeError = 'You have reached your upload limit. Please delete some files to continue.';
            }
        }
        if (totalSize > 150) { formatedFileData.uploadProgress = 100; }

        setUploadFileTempData(formatedFileData);

        setAttachmentData((record) => {
            const updatedItems = [...record];
            updatedItems.push(formatedFileData);
            return updatedItems;
        });

        return totalSize;
    }

    const uploadAttachment = (data) => {
        let fileData = data.target.files[0];
        if (fileData) {
            let duplicateEntry = attachmentData.filter(item => item.fileName == fileData.name);
            if (!duplicateEntry || duplicateEntry?.length == 0) {
                const randomFolderName = uploadFolder ? uploadFolder : randomFolderNameGenerator();
                if (!uploadFolder) {
                    setUploadFolder(randomFolderName);
                }
                const totalSize = formatAttachmentData(fileData, randomFolderName);

                if (totalSize <= 150) {
                    dispatch(uploadStart({ file: fileData, filename: fileData.name, folderId: randomFolderName }));
                } else {
                    setUploadProgressValue(100);
                }
            }
        }
    }

    const randomFolderNameGenerator = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 5) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const uploadFileEventsHandler = (event, file, newFileName) => {
        let updatedName = newFileName ? newFileName + '.' + file.fileExt : '';
        const fileUploadFolder = formHandlers.fieldsData?.id ? 'interview_request_' + formHandlers.fieldsData.id : uploadFolder;
        setSelectedFileRecord({ event, file, updatedName });
        switch (event) {
            case 'delete':
                setAlert({
                    type: 'simple',
                    message: 'Are you sure you want to delete this attachment?',
                    otherData: { fileName: file.fileName, fileFolder: fileUploadFolder },
                    show: true
                });
                break;
            case 'rename':
                if (newFileName) {
                    let duplicateEntry = attachmentData.filter(item => item.fileName == updatedName);
                    if (duplicateEntry?.length > 0) {
                        setSelectedFileRecord({ event: event, file: file, updatedName: null });
                        toast.warn('Entered name already exists.');
                    } else {
                        dispatch(getRenameFileAPIData({ folder: fileUploadFolder, file: file.fileName, newFileName: updatedName }));
                    }
                }
                break;
            case 'download':
                break;
            case 'reload':
                break;
        }
    }

    const closeConfirmHandler = (confirmFlag, otherData) => {
        if (confirmFlag) {
            dispatch(getDeleteFileAPIData({ folder: otherData.fileFolder, file: otherData.fileName }));
        }
        setAlert({});
    }

    const scrollToInvalidHandler = (field) => {
        if (field) {
            const InvalidInput = document.getElementsByName(field);
            if (InvalidInput[0]) {
                if (field.toLowerCase().includes('date')) {
                    InvalidInput[0].parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                else {
                    InvalidInput[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }
    }

    const getCaseTeamMembersDetails = (data) => {
        let caseTeamMembers = [];

        if (data.caseTeam?.length > 0) {
            data.caseTeam.forEach((member) => {
                const duplicateMember = caseTeamMembers.findIndex((c) => c.hrId == member.id);
                if (duplicateMember == -1) {
                    caseTeamMembers.push({
                        "hrId": member.id,
                        "memberEmail": member.email ? member.email : member.workEmail,
                        "memberType": "case_team_member",
                        "expertTypes": []
                    });
                }
            });
        }
        if (formHandlers.formView == 'edit') {
            if (data.caseTeamMembers?.length > 0) {
                if (caseTeamMembers.length > 0) {
                    caseTeamMembers.forEach((member, index) => {
                        const memberDetails = data.caseTeamMembers.findIndex((c) => c.hrId == member.hrId);
                        if (memberDetails > -1) {
                            caseTeamMembers[index] = data.caseTeamMembers[memberDetails];
                        }
                    });
                } else {
                    caseTeamMembers = [...data.caseTeamMembers];
                }
            }

            let duplicateRequestor = caseTeamMembers.findIndex((c) => c.memberType == "requestor");
            let requestorIndex = data.caseTeamMembers.findIndex((c) => c.memberType == "requestor");
            if (requestorIndex > -1 && (duplicateRequestor < 0)) {
                let requestorData = data.caseTeamMembers[requestorIndex];
                requestorData.memberIsdCode = data.phoneNo[0] ? data.phoneNo[0] : '';
                requestorData.memberPhoneNumber = data.phoneNo[1] ? data.phoneNo[1] : '';
                caseTeamMembers.push(requestorData);
            }
        } else {
            const duplicateMember = caseTeamMembers.findIndex((c) => c.memberType == "requestor");
            if (duplicateMember > -1) {
                caseTeamMembers[duplicateMember].memberIsdCode = data.phoneNo[0] ? data.phoneNo[0] : '';
                caseTeamMembers[duplicateMember].memberPhoneNumber = data.phoneNo[1] ? data.phoneNo[1] : '';
            } else {

                caseTeamMembers.push({
                    "hrId": data.hrId,
                    "memberName": data.requestorName,
                    "memberIsdCode": data.phoneNo && data.phoneNo[0] ? data.phoneNo[0] : '',
                    "memberPhoneNumber": data.phoneNo && data.phoneNo[1] ? data.phoneNo[1] : '',
                    "memberEmail": data.requestor,
                    "memberHomeOffice": data.office,
                    "memberTimezone": data.timezone,
                    "memberType": "requestor",
                    "expertTypes": []
                });
            }
        }

        if (data.expertContact?.length > 0) {

            //check for expertType perviously saved for case team members
            caseTeamMembers.forEach((cm) => {
                if (cm.expertTypes?.length > 0) {
                    let findExpIndex = -1, findMember = -2, savedExpIndex = -1;
                    cm.expertTypes.forEach((e, index) => {
                        findExpIndex = data.expertContact.findIndex((item) => item.expert == e.expertTypeName);
                        if (findExpIndex > -1 && data.expertContact[findExpIndex].member) {
                            findMember = data.expertContact[findExpIndex].member.findIndex((m) => m.id == cm.hrId);
                            if (findMember == -1) { savedExpIndex = index; }
                        }
                    });
                    if (findMember == -1 && savedExpIndex > -1) {
                        cm.expertTypes = cm.expertTypes.length == 1 ? [] : [
                            ...cm.expertTypes.slice(0, savedExpIndex), // Elements before the one to delete
                            ...cm.expertTypes.slice(savedExpIndex + 1) // Elements after the one to delete
                        ];
                    }
                }
            })

            data.expertContact.forEach((contact) => {
                const typeRecord = formHandlers.dropdownData.expertType.filter((item) => item.name === contact.expert);
                let expertDetail = {};
                if (typeRecord[0]?.id) {
                    expertDetail = { "expertTypeId": typeRecord[0].id, "expertTypeName": typeRecord[0].name, "type": typeRecord[0].type };
                } else {
                    expertDetail = { "expertTypeName": contact.expert, "type": "custom" };
                }
                if (contact.member?.length > 0) {
                    contact.member.forEach((person) => {
                        const existingTeamMember = caseTeamMembers.findIndex((mem) => mem.hrId == person.id);
                        if (existingTeamMember > -1) {
                            caseTeamMembers[existingTeamMember]['expertTypes'].push(expertDetail);
                        } else {
                            caseTeamMembers.push({
                                "hrId": person.id,
                                "memberEmail": person.email ? person.email : person.workEmail,
                                "memberType": "case_team_member",
                                "expertTypes": [expertDetail]
                            });
                        }
                    })
                }
            });
        }
        return caseTeamMembers;
    }

    const getExpertTypeDetails = (data) => {
        let expertTypeArray = [];
        data.forEach((type) => {
            const typeRecord = formHandlers.dropdownData.expertType.filter((item) => item.name === type);
            let expertDetail = {};
            if (typeRecord[0]?.id) {
                expertDetail = { "expertTypeId": typeRecord[0].id, "type": typeRecord[0].type };
            } else {
                expertDetail = { "expertTypeName": type, "type": "custom" };
            }
            expertTypeArray.push(expertDetail);
        });
        return expertTypeArray;
    }

    const getExpertNetworkDetails = (dataRM, dataP, previousData) => {
        let formatedData = previousData?.length > 0 ? [...previousData] : [];
        if (dataRM) {
            dataRM.forEach((elem) => {
                const duplicateValueIndex = formatedData.findIndex((e) => e.name == elem.resManager);
                if (duplicateValueIndex > -1) {
                    formatedData[duplicateValueIndex].researchManagerEmail = elem.email;
                } else {
                    const networkRecord = formHandlers.dropdownData.providers.filter((item) => item.providerName === elem.resManager);
                    formatedData.push({
                        "id": networkRecord[0].id,
                        "researchManagerEmail": elem.email
                    });
                }
            });
        }
        if (dataP?.length > 0 && (dataP?.length != dataRM?.length ||
            !dataRM || dataRM?.length == 0)) {
            dataP.forEach((pro) => {
                let available = [];
                const networkRecord = formHandlers.dropdownData.providers.filter((item) => item.providerName === pro);
                if (dataRM?.length > 0) {
                    available = dataRM.filter(item => item?.resManager == pro);
                }
                if (available.length == 0) {
                    formatedData.push({
                        "id": networkRecord[0].id,
                        "researchManagerEmail": ''
                    });
                }
            });
        }
        return formatedData;
    }

    const formatDateValue = (dateValue) => {
        let returnValue = '';
        if (typeof (dateValue) == "string") {
            return dateValue;
        }
        if (dateValue) {
            const year = dateValue.getFullYear();
            const month = (dateValue.getMonth() + 1).toString().length == 1 ? '0' + (dateValue.getMonth() + 1) : (dateValue.getMonth() + 1);
            const date = dateValue.getDate().toString().length == 1 ? '0' + dateValue.getDate() : dateValue.getDate();
            returnValue = year + '-' + month + '-' + date;
        }
        return returnValue;
    }

    const getProviderData = (data) => {
        let returnData = { id: '', type: '' };
        const projectTypeRecord = formHandlers.dropdownData.projectType.filter((item) => item.name === data);
        if (projectTypeRecord?.length > 0) {
            returnData = { id: projectTypeRecord[0].id, "type": projectTypeRecord[0].type };
        }
        return returnData;
    }

    const submitFormRequest = () => {
        if (!submitBtnClickedFlag) {
            let selectedForm = isSurveyForm ? 'Expert Surveys' : 'Expert Interviews';
            setSubmitBtnClicked(true);
            const formFieldSavedValues = formHandlers.fieldsData;
            let errorFlag = false, errorField = '';
            formFieldsData.forEach((field) => {
                if (field.id == 'phoneNo' && !phoneFieldValidityFlag) {
                    errorFlag = true;
                    errorField = 'requestor';
                } else if (field.error && field.isMandatory && !(field.id == 'providers' && formHandlers.formView == 'edit')) {
                    formHandlers.logInvalidFields(field.id);
                    if (!formFieldSavedValues[field.id] || formFieldSavedValues[field.id]?.length == 0 || formHandlers.invalidFields.indexOf(field.id) > -1) {
                        errorFlag = true;
                        if (!errorField) {
                            errorField = field.id;
                        }
                    }
                }
            });
            let invalidFieldsAvailable = ((isSurveyForm && formHandlers.invalidFields.length == 1 && formHandlers.invalidFields.includes("expertType")) || (formHandlers.invalidFields.length == 0)) ? false : true;
            if (formFieldSavedValues.formNotTouched) {
                setSubmitBtnClicked(false);
                window.scrollTo({ top: '90px', behavior: "smooth" });
            } else if (formHandlers.invalidFields.length == 1 && formHandlers.invalidFields.includes('phoneNo')) {
                errorField = 'requestor';
                setSubmitBtnClicked(false);
                scrollToInvalidHandler(errorField);
            } else if (invalidFieldsAvailable || errorFlag) {
                setSubmitBtnClicked(false);
                scrollToInvalidHandler(errorField);
            } else {
                setSaveAPICompleted(false);
                let requestData = {
                    "chargeCode": formFieldSavedValues.chargeCode,
                    "requestTitle": formFieldSavedValues.requestTitle,
                    "projectType": getProviderData(formFieldSavedValues.projectType),
                    "caseTeamMembers": getCaseTeamMembersDetails(formFieldSavedValues),
                    "requestDescription": formFieldSavedValues.requestDescription,
                    "attachmentsFolderName": formFieldSavedValues.attachmentsFolderName
                };
                if (isSurveyForm) {
                    requestData = {
                        ...requestData,
                        "incQuery": formFieldSavedValues.incQuery,
                        "surveyRespondentsCount": Number(formFieldSavedValues.totalSurvey),
                        "externalNetworks": getExpertNetworkDetails(formFieldSavedValues.resourceManager, formFieldSavedValues.providers),
                        "surveyStartDate": formatDateValue(formFieldSavedValues.surveyStartDate),
                        "surveyEndDate": formatDateValue(formFieldSavedValues.surveyEndDate)
                    };
                } else {
                    requestData = {
                        ...requestData,
                        "clientIndustries": formFieldSavedValues.clientIndustry,
                        "offLimitCompanies": formFieldSavedValues.offLimitCompanies,
                        "expertTypes": getExpertTypeDetails(formFieldSavedValues.expertType),
                        "interviewAnticipatedCount": Number(formFieldSavedValues.interviewAnticipatedCount),
                        "externalNetworks": getExpertNetworkDetails(formFieldSavedValues.resourceManager, formFieldSavedValues.providers, formFieldSavedValues.externalNetworks),
                        "interviewAnticipatedStartDate": formFieldSavedValues.id ? formFieldSavedValues.interviewStartDate : formatDateValue(formFieldSavedValues.interviewStartDate),
                        "interviewAnticipatedEndDate": formatDateValue(formFieldSavedValues.interviewEndDate),
                    };
                }
                if (formHandlers.formView == 'edit') {
                    requestData.attachmentsDetails = formFieldSavedValues.attachmentsDetails ? formFieldSavedValues.attachmentsDetails : [];
                }

                initiateSubmitClickAnalytics('EEH Request Form', selectedForm, 'Initiate Expert Request', 'EEH Request Form');
                dispatch(getSaveInterviewAPIData({ data: requestData, flag: isSurveyForm, requestId: formFieldSavedValues.id }));
            }
        }
    }

    const onDatePickerOpen = async (event) => {
        scrollToInvalidHandler('requestor');
    }

    return (
        <>
            {alert.show != undefined && alert.show != null && <AlertPopup
                open={alert.show}
                type={alert.type}
                message={alert.message}
                otherData={alert.otherData}
                errorMsg={alert.errorMsg}
                isNewRequest={alert.newRequest}
                requestId={alert.requestId}
                requestType={alert.requestType}
                projectType={alert.projectType}
                closeAlertHandler={closeConfirmHandler}
            />}

            <div className='expertInterviewForm__wrap'>
                <div className='expertInterviewForm__rightsection'>
                    {isSurveyForm &&
                        <div className="survey-alert">
                            <img alt="infoIcon" src={infoIcon}></img>
                            <div>Please note that your survey request will be addressed by selected providers via email and managed offline for the duration of EEH trial.</div>
                        </div>
                    }

                    <div className="expertInterviewForm__formwrap">
                        <div className="expertInterviewForm__forminnerwrap">
                            <form ref={requestFormRef} onSubmit={handleSubmit} className="was-vawlidated">
                                <div>
                                    <FormFields fieldsData={formFieldsData} invalidFields={invalidFields} updateCaseTeamData={updateCaseTeamMembers} oldProvidersData={oldProvidersData} isSurveyForm={isSurveyForm}
                                        handleFieldChange={handleChange} validateField={validateErrors} handlePhoneChange={handlePhoneChange} attachments={attachmentData} uploadProgress={uploadProgressValue} uploadFileID={uploadFileID}
                                        onProvidersSelected={handleProviderData} onRscManagerSelected={handleSelManagers} uploadFileHandler={uploadAttachment} handleUploadFileEvents={uploadFileEventsHandler} updatePhoneFieldFlag={(flag) => setPhoneFieldValidityFlag(flag)}
                                        onDatePickerOpenHandler={(e) => onDatePickerOpen(e)} />
                                    <div className="field-divider"></div>
                                    <div className="other-details width70">
                                        <p>By submitting this form, you agree that you understand the potential conflicts and risks when interviewing experts:</p>
                                        <ul>
                                            <li>&#8226; <span>Avoid contacting current employees of BCG&apos;s client, current employees of competitors and employees of your target firm.</span></li>
                                            <li>&#8226; <span>Never ask an expert to reveal non-public or sensitive information.</span></li>
                                        </ul>

                                        <div className="review-link">
                                            <div className="review-link-inner-block">
                                                <img alt="uploadIcon" src={flag}></img>
                                                <a href="https://pages.navigator.bcg.com/kp/ad32eab2-c4bc-4922-9df9-c4a8c2174033" target="_blank" rel="noopener noreferrer">Review External Expert Compliance Guidelines</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
            <div className="form-footer">
                <button className='default-button' onClick={() => submitFormRequest()}>Submit</button>
                {
                    loading === true && submitBtnClickedFlag &&
                    <div class="ui active inline medium loader custom-loader"></div>
                }
            </div>
        </>
    )
};
