import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "containers/Layout";
import { Table, Checkbox } from 'semantic-ui-react';
import { getPeopleAPIData } from "redux/actions/peopleresults/peopleresults.actions";
import { getUserId } from "utils/auth/auth";
import Image from "components/shared/Image";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import CrossIcon from "assets/images/filter-close.svg";
import ChargeCode from "assets/images/charge-code.svg";
import { PerformanceTable } from './PerformaceTable';
import { Bar } from 'react-chartjs-2';
import './ProjectAnalytics.scss';

import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export const ProjectAnalytics = () => {
    const dispatch = useDispatch();
    const suggestionBoxRef = useRef(null);
    const dummyData = [
        {
            "name": "Adeel Solangi",
            "reqId": "123467654",
            "id": "V59OF92YF627HFY0",
            "bio": "Donec lobortis eleifend condimentum. Cras dictum dolor lacinia lectus vehicula rutrum. Maecenas quis nisi nunc. Nam tristique feugiat est vitae mollis. Maecenas quis nisi nunc.",
            "version": 6.1
        },
        {
            "name": "Afzal Ghaffar",
            "reqId": "123467654",
            "id": "ENTOCR13RSCLZ6KU",
            "bio": "Aliquam sollicitudin ante ligula, eget malesuada nibh efficitur et. Pellentesque massa sem, scelerisque sit amet odio id, cursus tempor urna. Etiam congue dignissim volutpat. Vestibulum pharetra libero et velit gravida euismod.",
            "version": 1.88
        },
        {
            "name": "Aamir Solangi",
            "reqId": "3565",
            "id": "IAKPO3R4761JDRVG",
            "bio": "Vestibulum pharetra libero et velit gravida euismod. Quisque mauris ligula, efficitur porttitor sodales ac, lacinia non ex. Fusce eu ultrices elit, vel posuere neque.",
            "version": 7.27
        },
        {
            "name": "Abla Dilmurat",
            "reqId": "009-24",
            "id": "5ZVOEPMJUI4MB4EN",
            "bio": "Donec lobortis eleifend condimentum. Morbi ac tellus erat.",
            "version": 2.53
        },
        {
            "name": "Adil Eli",
            "reqId": "456-34",
            "id": "6VTI8X6LL0MMPJCC",
            "bio": "Vivamus id faucibus velit, id posuere leo. Morbi vitae nisi lacinia, laoreet lorem nec, egestas orci. Suspendisse potenti.",
            "version": 6.49
        },
        {
            "name": "Adile Qadir",
            "reqId": "Uyghur",
            "id": "4534562",
            "bio": "Duis commodo orci ut dolor iaculis facilisis. Morbi ultricies consequat ligula posuere eleifend. Aenean finibus in tortor vel aliquet. Fusce eu ultrices elit, vel posuere neque.",
            "version": 1.9
        },
        {
            "name": "Abdukerim Ibrahim",
            "reqId": "86543",
            "id": "LO6DVTZLRK68528I",
            "bio": "Vivamus id faucibus velit, id posuere leo. Nunc aliquet sodales nunc a pulvinar. Nunc aliquet sodales nunc a pulvinar. Ut viverra quis eros eu tincidunt.",
            "version": 5.9
        },
        {
            "name": "Adil Abro",
            "reqId": "123467654",
            "id": "LJRIULRNJFCNZJAJ",
            "bio": "Etiam malesuada blandit erat, nec ultricies leo maximus sed. Fusce congue aliquam elit ut luctus. Etiam malesuada blandit erat, nec ultricies leo maximus sed. Cras dictum dolor lacinia lectus vehicula rutrum. Integer vehicula, arcu sit amet egestas efficitur, orci justo interdum massa, eget ullamcorper risus ligula tristique libero.",
            "version": 9.32
        }];

    const analyticsData = [{
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: [
            '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    },
    {
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: [
            '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    },
    {
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia Proposed to develop a SME strategy for a Bank and financial service firm in AsiaProposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: [
            '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)', '#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    },
    {
        chargeCode: '45-67890-98',
        details: 'Proposed to develop a SME strategy for a Bank and financial service firm in Asia',
        spendAmt: '120,000',
        requests: ['#12-785-98 (Proposed to develop a SME strategy for a Bank and financial service firm in Asia)'
        ]
    }
    ]

    const [searchInput, setSearchInput] = useState('');
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionBoxRef?.current && !suggestionBoxRef?.current?.contains(event.target)) {
                setShowSuggestion(false);
                // setSearchInput('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchHrId();
    }, [history.location]);

    const fetchHrId = async () => {
        const hrid = await getUserId();
        dispatch(getPeopleAPIData(hrid));
    }
    const handleSearchChange = (event) => {
        event.stopPropagation();
        const searchTerm = event?.target?.value?.toLowerCase();
        setSearchInput(searchTerm);
        if (searchTerm?.length > 0) {
            setShowSuggestion(true);
        }
        else if (searchTerm?.length === 0) {
            setShowSuggestion(false);
        }
    }
    const handleKeyUp = (e) => {
        if (e.keyCode === 32) {
            e.target.value = e.target.value + " "
            e.stopPropagation()
        }
    }

    const chartDataNetwork = {
        labels: ['Alphasights', 'Intollion India', 'Coleman', 'Dialectica'],
        datasets: [
            {
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: '#A97CDD',
                borderColor: '#A97CDD',
                borderWidth: 0,
            },
        ],
    };

    const chartDataExpert = {
        labels: ['Customer', 'Former', 'Supplier', 'Competitor'],
        datasets: [
            {
                data: [15, 29, 40, 31, 56, 25, 40],
                backgroundColor: '#0977D7',
                borderColor: '#0977D7',
                borderWidth: 0,
            },
        ],
    };

    const chartOptions = {
        scales: {
            x: {
                grid: {
                  display: false, // Hide grid lines on x-axis
                },
              },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Hide grid lines on x-axis
                  },
            },
        },
        plugins: {
            legend: {
              display: false, // Hide legend
            },
          },
            indexAxis: 'y',
    };

    return (
        <Layout id="home" layoutClass="layout__my-navigator">
            <div className="ProjectAnalytics__wrap">
                <div className="analytics__searchWrap">
                    <div className="search--text">Use the search box to add multiple Interview Request IDs or Charge Codes and view consolidated analytics.</div>
                    <div className="search--field">
                        <div className="search__box">
                            <input type="text"
                                value={searchInput}
                                onChange={(e) => handleSearchChange(e)}
                                onKeyUp={(e) => {
                                    handleKeyUp(e);
                                }
                                }
                                placeholder="Enter Interview Request IDs or Charge Codes"
                                className="search--input" />
                            {
                                searchInput?.length > 0 &&
                                <Image src={CrossIcon} alt="CrossIcon" className="cross-icon" onClick={() => {
                                    setSearchInput('');
                                }} />
                            }
                            <Image src={GreenSearchIcon} alt="search icon" className={`search--icon ${searchInput.length <= 0 && "disable--icon"}`}
                            // onClick={() => searchExpertNameHandler(searchInput)} 
                            />
                        </div>
                        {
                            showSuggestion &&
                            <div className="suggestion__wrap" ref={suggestionBoxRef}>
                                {
                                    dummyData?.map((item, index) => (
                                        <div className="suggestion__itemWrap" key={item?.id}>
                                            <div className="suggestion__left">
                                                <Checkbox label={item}
                                                    // onChange={handleCheckBoxChange} 
                                                    // checked={selectedCheckBoxes?.includes(item?.profileId)} 

                                                    className='een-checkbox table--check' />
                                            </div>
                                            <div className="suggestion__right">
                                                <div className="right__top">
                                                    <div className="req--id">
                                                        Interview Request ID :
                                                    </div>
                                                    <div className="req--idVal">
                                                        {item?.reqId}
                                                    </div>
                                                </div>
                                                <div className="right__bottom">
                                                    {item?.bio}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>

                <div className="analytics__body">
                    <div className="body--text">Showing Analytics for </div>

                    <div className={hoveredItemIndex !== null ? "no--overflow body__wrap" : "body__wrap"}>
                        {
                            analyticsData?.map((item, index) => (
                                <><div className={`body__item ${hoveredItemIndex === index ? 'hovered' : ''}`} key={index} onMouseEnter={() => setHoveredItemIndex(index)} // Set hovered index
                                    onMouseLeave={() => setHoveredItemIndex(null)} >
                                    <>
                                        <Image src={CrossIcon} alt="CrossIcon" className="chargecross-icon" />
                                        <div className="charge__code">
                                            <Image src={ChargeCode} alt="ChargeCode" className="item-img" />
                                            <div className="code--val">Charge Code : {item?.chargeCode}</div>
                                        </div>

                                        <div className="code--text">{item?.details}</div>

                                        <div className="spend--text">Actual Spend</div>
                                        <div className="code--spend">
                                            ${item?.spendAmt}
                                        </div>
                                    </>
                                    <div className={`requests__wrap `}>
                                        <div className='request--text'>Requests</div>
                                        {item?.requests?.map((subItem, subIndex) => (
                                            <div className="requests__item" key={subIndex}>
                                                <div className="request--detail">
                                                    a. {subItem}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div></>
                            ))
                        }
                    </div>
                </div>

                <div className="lower__body">
                    <div className="spend__overView overview-block">
                    <div className="block-header">Spend Overview</div>
                    <div className="block-sub-heading">Total Estimated Spend <span className="highlighted-text">$ 600,000</span></div>
                        <div className="chart-overview">
                        <div className="spend-chart1 chart-block">
                            <div className="chart-heading1">Actual spend</div>
                            <div className="chart-heading2">By Network</div>
                        <Bar data={chartDataNetwork} options={chartOptions} />
                        </div>
                        <div className="spend-chart2 chart-block">
                            <div className="chart-heading1">Actual spend</div>
                            <div className="chart-heading2">by Expert Type</div>
                        <Bar data={chartDataExpert} options={chartOptions} />
                        </div>
                        </div>
                    </div>
                    <div className="performance__overview overview-block">
                        <div className="block-header">Performance Overview</div>
                        <div className="block-sub-heading ">Vendor Performance</div>
                        <div className="vendortable--text">Percentage of total project engagements facilitated by each expert network.</div>
                        <PerformanceTable />
                    </div>
                </div>
            </div>
        </Layout>
    )
}