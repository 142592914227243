import React, { useEffect, useState } from 'react';
import './NoAccessPopup.scss';
import Modal from "components/shared/Modal";
import CloseIcon from 'assets/images/close-popup.svg';
import Image from "components/shared/Image";

export const NoAccessPopup = ({ openModel, handleClosePopup }) => {
    return (
        <>
            <Modal
                isOpen={openModel}
                className="noAccess--modal">
                <div className='header'>
                    <div className='header__left'>
                        Request Access
                    </div>
                    <div className="header__right">
                        <Image src={CloseIcon} alt="closeIcon" className="compare--details" onClick={() => { handleClosePopup() }} />
                    </div>
                </div>
                <div className="noAccess__body">
                    <div className="noAccess--bodyText">
                        Your access request has been submitted successfully. You will receive a confirmation email shortly.
                    </div>
                </div>

                <div className="noAccess__footer">
                    <button className={"approve--btn"} onClick={() => handleClosePopup()}>Close</button>
                </div>
            </Modal>
        </>
    )
}