import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "utils/auth/auth";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { selectors as requestDetailsSelectors } from 'redux/reducers/requestDetails/requestDetails_reducer';
import { selectors as noAccessSelectors } from 'redux/reducers/NoAccess/NoAccess_reducer';
import { noAccessAPIData, clearNoAccessAPIData } from 'redux/actions/NoAccess/NoAccess.actions';
import { NoAccessPopup } from './NoAccessPopup';
import queryString from 'query-string';
import './NoAccess.scss';

export const NoAccess = () => {
    const dispatch = useDispatch();
    const requestorInfo = useSelector(requestDetailsSelectors?.getRequestDetailsResults);
    const noAccessData = useSelector(noAccessSelectors?.getNoAccessResultsResults);
    const loadingData = useSelector(noAccessSelectors?.getLoading);
    const [getCaseTeamMemberData, setCaseTeamMemberData] = useState({});
    const queryParam = queryString.parse(location.search)?.interviewId;
    const [getRequestorId, setRequestorId] = useState('');
    const [getRequestorData, setRequestorData] = useState('');
    const [noAccessResp, setNoAccessResp] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [noAccessData, setNoAccessData] = useState('');

    useEffect(() => {
        setLoading(loadingData);
    }, [loadingData]);

    useEffect(() => {
        console.log('noAccessData>>>', noAccessData);
        if (noAccessData?.length > 0) {
            setNoAccessResp(true);
        }
    }, [noAccessData]);

    const handleClosePopup = () => {
        setNoAccessResp(false);
        dispatch(clearNoAccessAPIData());
    }

    useEffect(() => {
        if (requestorInfo?.caseTeamMembers) {
            let filteredRequestor;
            filteredRequestor = requestorInfo?.caseTeamMembers?.find((item) => {
                return item?.memberType === 'requestor'
            });
            setRequestorId(filteredRequestor?.hrId);
            fetchRequestorData(filteredRequestor?.hrId);
        }
    }, [requestorInfo]);

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            setCaseTeamMemberData({
                "caseTeamName": resp[0]?.preferredName,
                "caseTeamEmail": resp[0]?.email,
            });
        }
    }
    const fetchRequestorData = async (id) => {
        const resp = await getUserProfilePictures([id]);
        if (resp?.length > 0) {
            setRequestorData({
                "requestorName": resp[0]?.preferredName,
                "requestorEmail": resp[0]?.email,
            });
        }
    }
    const handleRequestAccess = () => {
        const queryData = {
            "requestId": Number(queryParam),
            "requestDetailsUrl": window?.location?.href,
            "requestorName": getRequestorData?.requestorName,
            "requestorEmail": getRequestorData?.requestorEmail,
            "teamMemberName": getCaseTeamMemberData?.caseTeamName,
            "teamMemberEmail": getCaseTeamMemberData?.caseTeamEmail
        }
        console.log('queryData', queryData);
        dispatch(noAccessAPIData(queryData));
    }

    return (
        <>
            <div className="NoAccess__wrapper">
                <div className="noAccess__header">
                    Access Restricted
                </div>
                <div className="noAccess__body">
                    Unfortunately, you do not currently have permission to access this request. If you need access, please click below to request permission or contact your system administrator for further assistance.
                </div>
                <div className="noAccess__footer">
                    <button className={loading ? "request--btn request--btnDisabled" : "request--btn"} onClick={() => handleRequestAccess()}>Request Access</button>
                    <button className="contactus--btn">Contact Support</button>
                </div>
            </div>
            {
                noAccessResp && <NoAccessPopup openModel={noAccessResp} handleClosePopup={handleClosePopup} />
            }
        </>
    )
}