import { SAVE_INTERVIEW_REQUEST } from 'redux/constants';

//  People CCO actions
export const getSaveInterviewAPIData = (requestData) => ({
    type: SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST,
    payload: requestData
});

export const getSaveInterviewAPIDataPending = () => ({
    type: SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST_PENDING
});

export const getSaveInterviewAPIDataSuccess = (payload) => ({
    type: SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST_SUCCESS,
    payload
});

export const getCloseRequestAPIDataSuccess = (payload) => ({
    type: SAVE_INTERVIEW_REQUEST.API_CLOSE_INTERVIEW_REQUEST_SUCCESS,
    payload
});

export const getSaveInterviewAPIDataFailure = (error) => ({
    type: SAVE_INTERVIEW_REQUEST.API_SAVE_INTERVIEW_REQUEST_FAILURE,
    payload: { error: error?.toString() }
});
