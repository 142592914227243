import React, { useEffect, useState } from "react";
import "./ActionAlertWindow.scss";
import Modal from "components/shared/Modal";

import CrossIcon from "assets/images/cross-large.svg";

export const ActionAlertWindow = ({ props,closeAlertWindowHandler, children }) => {
    const [openModel, setOpenModel] = useState(props.open);

    useEffect(()=>{
        setOpenModel(props.open);
    },[props.open]);

    const buttonClickHandler = (isCancel) => {
        closeAlertWindowHandler(isCancel);
    }

    return (
        <Modal isOpen={openModel} className="action-alert__modal">
            <div className="action-alert__wrapper" style={{ width: props.popupWidth, height: props.popupHeight }}>
                {props.showCloseIcon && <img className="close-icon" src={CrossIcon} alt="close" onClick={() => buttonClickHandler(true)} />}
                <div className="action-alert__heading">
                    <span>{props.heading}</span>
                </div>
                {props.details &&
                    <div className="action-alert__details">
                        <p>{props.details}</p>
                    </div>
                }
                {children}
                <div className="action-alert__action-container">
                    <button className="outline" onClick={() => buttonClickHandler(true)}>Cancel</button>
                    <button className="primary" onClick={() => buttonClickHandler(false)} disabled={props.chargeCode ? false: true}>Proceed</button>
                </div>
            </div>
        </Modal>
    );
};
