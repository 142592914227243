import React, { useContext, useEffect, useState } from "react";
import authService from 'redux/auth';
import { PeopleSelector } from "@kdshared/peopleselector";
import { Image, Progress } from "semantic-ui-react";

import './FormFields.scss';
import CONFIG from "config";

import { Provider } from "components/shared/Provider";
import { ResourceManager } from "components/shared/ResourceManager";
import { PhoneNumber } from "components/PhoneNumber";
import { SearchableField } from "components/shared/SearchableField";
import { DatePickerField } from "components/DatePickerField";
import { DropdownField } from "components/shared/DropdownField";
import { ExpertContact } from "components/shared/ExpertContact";
import { UploadAttachments } from "../UploadAttachments";
import { ToggleSwitch } from "../ToggleSwitch";

import { FormContext } from "context/form-context";

import { getUserProfilePictures } from "api/getUserProfilePictures";

import PDF from "assets/images/fileIcons/PDF.svg";
import GIF from "assets/images/fileIcons/GIF.svg";
import JPG from "assets/images/fileIcons/JPG.svg";
import MP3 from "assets/images/fileIcons/MP3.svg";
import PNG from "assets/images/fileIcons/PNG.svg";
import XLS from "assets/images/fileIcons/XLS.svg";
import DOC from "assets/images/fileIcons/DOC.svg";
import DOCX from "assets/images/fileIcons/DOCX.svg";
import CSV from "assets/images/fileIcons/CSV.svg";
import EXE from "assets/images/fileIcons/EXE.svg";
import XLSX from "assets/images/fileIcons/XLSX.svg";
import FileIcon from "assets/images/file_icon.svg";
import CrossIcon from "assets/images/upload-cross.svg";
import DeleteIcon from "assets/images/trash_icon.svg";
import EditIcon from "assets/images/edit-icon.svg";
import CheckIcon from "assets/images/tick.svg";
import DownloadIcon from "assets/images/download.svg";
import ReloadIcon from "assets/images/reload.svg";

export const FormFields = ({ fieldsData, uploadFileHandler, invalidFields, handleFieldChange, validateField, onProvidersSelected, onRscManagerSelected, isSurveyForm, handlePhoneChange, attachments, uploadProgress, handleUploadFileEvents, uploadFileID, updatePhoneFieldFlag, onDatePickerOpenHandler }) => {
    const IconMap = {
        'PDF': PDF,
        'GIF': GIF,
        'JPG': JPG,
        'MP3': MP3,
        'PNG': PNG,
        'XLS': XLS,
        'DOC': DOC,
        'DOCX': DOCX,
        'CSV': CSV,
        'XLSX': XLSX,
        'EXE': EXE
    };

    const formContext = useContext(FormContext);
    const dropdownOptions = formContext.dropdownData;

    const [accessToken, setAccessToken] = useState('');
    const [fileNameEditFieldVisible, setFileNameEditFieldVisible] = useState(false);
    const [fileNewName, setFileNewName] = useState('');
    const [selectedFileId, setSelectedFileId] = useState(0);
    const [savedCaseTeamMembers, setSavedCaseTeamMembers] = useState([]);
    const [savedExpertTypes, setSavedExpertTypes] = useState([]);
    const [peoplePrefilledList, setPeoplePrefilledList] = useState([]);
    const [invalidPhoneNo, setInvalidPhoneNo] = useState(true);

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
    }, []);

    useEffect(() => {
        updatePhoneFieldFlag(invalidPhoneNo);
    }, [invalidPhoneNo]);

    useEffect(() => {
        formContext.fieldsData['chargeCode'] = '';
        setSavedCaseTeamMembers([]);
        if (formContext.fieldsData['caseTeamMembers']) {
            let caseTeamHridArr = [];
            formContext.fieldsData['caseTeamMembers'].forEach((member) => {
                if (member?.memberType != "requestor") {
                    caseTeamHridArr.push(member.hrId);
                    getCaseteamMemberName(member);
                }
            });
            setPeoplePrefilledList(caseTeamHridArr);
        }
    }, [isSurveyForm, formContext.fieldsData.caseTeamMembers]);

    useEffect(() => {
        if (savedCaseTeamMembers?.length > 0 && formContext.databaseValues.expertType?.length > 0) {
            formContext.databaseValues.expertType.forEach((value) => {
                if (value.member?.length > 0) {
                    value.member.forEach((e) => {
                        const availableRecord = savedCaseTeamMembers.findIndex((rec) => rec.hrId == e);
                        if (availableRecord != -1) {
                            setSavedExpertTypes((types) => {
                                const expertItems = [...types];
                                let duplicateIndex = expertItems.findIndex((mem) => mem.name == value.name);
                                if (duplicateIndex == -1) {
                                    expertItems.push({ name: value.name, member: [{ name: savedCaseTeamMembers[availableRecord].name, id: savedCaseTeamMembers[availableRecord].hrId }] });
                                } else {
                                    if (expertItems[duplicateIndex].member.findIndex((i) => i.name == savedCaseTeamMembers[availableRecord].name) == -1)
                                        expertItems[duplicateIndex].member.push({ name: savedCaseTeamMembers[availableRecord].name, id: savedCaseTeamMembers[availableRecord].hrId });
                                }
                                return expertItems;
                            });
                        }
                    });
                }
            })
        }
    }, [savedCaseTeamMembers, formContext.databaseValues.expertType]);

    useEffect(() => {
        const handleClickOutside = () => {
            if (fileNameEditFieldVisible) {
                handleUploadFileEvents('rename', '', '');
                setFileNameEditFieldVisible(false);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('file-name-input') && !event.target.classList.contains('save-file-name')) {
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [fileNameEditFieldVisible]);

    const getCaseteamMemberName = async (caseTeamMember) => {
        const resp = await getUserProfilePictures([caseTeamMember.hrId]);
        if (resp) {
            setSavedCaseTeamMembers((member) => {
                const updatedItems = [...member];
                let duplicateIndex = updatedItems.findIndex((mem) => mem.hrId == caseTeamMember.hrId);
                if (duplicateIndex == -1) {
                    updatedItems.push({
                        name: resp[0]?.lastName?.concat(', ').concat(resp[0]?.firstName),
                        hrId: caseTeamMember.hrId,
                        isExpertConnected: (caseTeamMember?.expertTypes?.length > 0)
                    });
                }
                return updatedItems;
            });
            return true;
        }
    };

    const saveNewFileName = (file) => {
        handleUploadFileEvents('rename', file, fileNewName);
        setFileNameEditFieldVisible(false);
    }

    const updateSelection = (e) => {
        onRscManagerSelected(e.selectionArray, 'caseTeam');
    }

    return (
        <>
            {fieldsData && fieldsData.map((field, index) => (
                <div className={`${field.size == 'small' ? 'small' : 'width70'}`} key={index}>
                    {field.type == 'title' ? <>
                        {index > 1 && <div className="field-divider"></div>}
                        <div className="form-fields__heading">
                            {field.label}
                        </div>
                    </>
                        : field.label && <>
                            <label htmlFor={field.id} className="form-label">
                                {field.label}
                                {
                                    field.isMandatory &&
                                    <span className="mandatory">*</span>
                                }</label>
                            {field.subLabel && <p htmlFor={field.id} className="form-label sub-label">{field.subLabel}{
                                field.isMandatory &&
                                <span className="mandatory">*</span>
                            }</p>}
                        </>
                    }
                    <div className="name__field">
                        <div className={`${field.size == 'small' ? '' : 'width70'}`}>
                            {field.type == 'searchable' &&
                                <SearchableField 
                                fieldId={field.id} 
                                preferredListHeight='250px'
                                isValid={field.isValid}
                                isDisable={formContext.formView == 'edit'}
                                savedValue={formContext?.fieldsData?.chargeCode}
                                handleChange={handleFieldChange} 
                                validateErrors={validateField} 
                                onChargeCodeSelected={onRscManagerSelected} 
                                isSurveyForm={isSurveyForm} />
                            }
                            {field.type == 'text' &&
                                <input
                                    type="text"
                                    className={`input-fields form-input-fields ${invalidFields.includes(field.id) && 'form-control is-invalid'}`}
                                    id={field.id}
                                    name={field.id}
                                    value={formContext.fieldsData[field.id]}
                                    placeholder={field.placeholder}
                                    onChange={(event) => { handleFieldChange(event); }}
                                    onBlur={(event) => validateField(event, field.id)}
                                />
                            }
                            {field.type == 'number' &&
                                <input
                                    type="number"
                                    className={`input-fields form-input-fields ${invalidFields.includes(field.id) && 'form-control is-invalid'}`}
                                    id={field.id}
                                    name={field.id}
                                    value={formContext.fieldsData[field.id]}
                                    placeholder={field.placeholder}
                                    min='0'
                                    onChange={(event) => { handleFieldChange(event); }}
                                    onBlur={(event) => validateField(event, field.id)}
                                />
                            }
                            {field.type == 'textarea' &&
                                <textarea className={`form-textarea ${invalidFields.includes(field.id) && 'form-control is-invalid'}`}
                                    id={field.id} name={field.id} placeholder={field.placeholder}
                                    value={formContext.fieldsData[field.id]}
                                    onChange={(event) => { handleFieldChange(event) }}
                                    onBlur={(event) => validateField(event, field.id)}></textarea>
                            }
                            {field.type == 'toggle' &&
                                <ToggleSwitch id={field.id} name={field.id} isOn={formContext.fieldsData[field.id]}
                                    handleToggle={() =>
                                        onRscManagerSelected(!formContext.fieldsData[field.id], field.id)
                                    } />
                            }
                            {field.type == 'date' &&
                                <DatePickerField
                                    fieldId={field.id}
                                    savedValue={formContext.fieldsData[field.id]}
                                    formView={formContext.formView}
                                    isDisabled={formContext.formView == 'edit' && field.id.indexOf('StartDate') > -1}
                                    isInvalidField={invalidFields.includes(field.id)}
                                    minDateValue={field.id.indexOf('StartDate') > -1 ? new Date() : isSurveyForm ? formContext.fieldsData.surveyStartDate : formContext.fieldsData.interviewStartDate}
                                    onDateSelectHandler={(value) => onRscManagerSelected(value, field.id)}
                                    onCalOpen={(event) => onDatePickerOpenHandler(event)}
                                    onCalClose={(event) => validateField(event, field.id)} />
                            }
                            {field.type == 'select' && <>
                                <DropdownField data={dropdownOptions[field.id]} isInvalidField={invalidFields.includes(field.id)} isDisabled={formContext.formView == 'edit' && field.id == 'projectType'} selectedValue={formContext.fieldsData[field.id]} disabledValues={formContext.databaseValues[field.id]} isMultiSelect={field.isMultiSelect} isAddNewAvailable={field.customFieldOption} fieldIndex='0' placeholderTxt={field.placeholder} fieldId={field.id} name={field.id} formErrorValid={!invalidFields.includes(field.id)}
                                    onSelectHandler={(selected) => {
                                        if (formContext.formView == 'edit' && field.id == 'expertType') {
                                            let selection = [];
                                            if (formContext.databaseValues['expertType']?.length > 0) {
                                                formContext.databaseValues['expertType'].forEach((item) => {
                                                    if (!selection.includes(item.name)) {
                                                        selection.push(item.name);
                                                    }
                                                })
                                            }
                                            selected = selection.concat(selected);
                                        }
                                        onRscManagerSelected(selected, field.id);
                                    }} onFocusOutHandler={(event) => validateField(formContext.fieldsData[field.id], field.id, true, true)} />
                                {formContext.databaseValues[field.id] && field.id == 'expertType' &&
                                    <div className="edit-caseteam">
                                        {formContext.databaseValues[field.id]?.map((type, index) =>
                                            <div key={index} className={`saved-case-team-member`}><span>{type.name}</span></div>
                                        )}
                                    </div>
                                }
                            </>
                            }
                        </div>
                        {field.type == 'custom' &&
                            <>
                                <div className={`${field.size == 'small' ? '' : 'width70'}`}>
                                    {field.id == 'upload' && <>
                                        <UploadAttachments uploadFileHandler={uploadFileHandler} />
                                    </>
                                    }
                                    {field.id == 'caseTeam' && accessToken &&
                                        <PeopleSelector
                                            label=''
                                            name="originalRequestor"
                                            isInputFirst={false}
                                            apiKey={CONFIG.PEOPLE_API_X_API_KEY}
                                            accessToken={accessToken}
                                            environment='production-internal'
                                            fieldHeight="48px"
                                            preFillData={[peoplePrefilledList]}
                                            onChange={(e) => updateSelection(e)}
                                        />
                                    }
                                    {field.id == 'phoneNo' &&
                                        <PhoneNumber requestorPhone={formContext.fieldsData[field.id]} handlePhoneValue={(data, flag) => handlePhoneChange(data, flag)} disablePhoneField={formContext.formView == 'edit' && formContext.fieldsData.hrId != formContext.fieldsData.originalRequestorHrid ? true : false} handleInvalidPhoneValue={(flag) => setInvalidPhoneNo(flag)} />
                                    }
                                    {field.id == 'providers' &&
                                        <Provider id={field.id} fieldId={field.id} isSurveyForm={isSurveyForm} providersData={dropdownOptions.providers} anticipatedInterviewsCount={formContext.fieldsData.interviewAnticipatedCount} formErrorValid={!invalidFields.includes(field.id)}
                                            handleProviderData={(updatedItems, selectedCheckBoxes) => {
                                                onProvidersSelected(updatedItems, selectedCheckBoxes, field.id);
                                            }}
                                            onFocusOutHandler={(event) => validateField(formContext.fieldsData[field.id], field.id, true)} />
                                    }
                                </div>
                                {field.id == 'resourceManager' &&
                                    <ResourceManager selectedProvidersList={formContext.fieldsData.providers} savedDetails={formContext.fieldsData.externalNetworks} isDisabled={(formContext.fieldsData?.providers?.length > 0 || formContext.fieldsData?.externalNetworks?.length > 0) ? false : true} />
                                }
                                {field.id == 'expertContact' &&
                                    <ExpertContact
                                        selectedProviders={formContext.fieldsData.expertType}
                                        prevSelection={formContext.formView == 'edit' ? savedExpertTypes : []}
                                        isDisabled={formContext.fieldsData.expertType?.length > 0 ? false : true}
                                        appName={CONFIG.applicationName} apiKey={CONFIG.PEOPLE_API_X_API_KEY}
                                        accessToken={accessToken}
                                        handleSelManagers={(data) => { onRscManagerSelected(data, 'expertContact') }}
                                        placeholderTxt={field.placeholder} />
                                }
                            </>
                        }
                        <div className={`${field.size == 'small' ? '' : 'width70'}`}>
                            {field.type == 'readonly' &&
                                <input
                                    type="text"
                                    className={`input-fields form-input-fields ${invalidFields.includes(field.id) && 'form-control is-invalid'} disabled`}
                                    id={field.id}
                                    name={field.id}
                                    value={formContext.fieldsData[field.id]}
                                    readOnly
                                />
                            }
                        </div>
                        {invalidFields.includes(field.id) && <div className="error-txt width70">{field.error}</div>}
                        <div className={`help-txt ${field.size != 'small' && 'width70'}`}>
                            {field.id == 'expertType' && formContext.formView == 'edit' ?
                                <span>The current expert type/module cannot be removed; only new entries can be added.</span>
                                : <span>{field.helpTxt}</span>
                            }
                        </div>
                        {field.id == 'upload' && attachments?.length > 0 &&
                            attachments.map((file, index) => (
                                <div key={file.fileId}>
                                    <div className='uploaded-file'>
                                        {IconMap[file.fileExt.toUpperCase()] ?
                                            <Image src={IconMap[file.fileExt.toUpperCase()]} alt="file icon" />
                                            : <Image src={FileIcon} alt="file icon" />}
                                        {file.fileId == selectedFileId && fileNameEditFieldVisible ?
                                            <div className="new-file-name-block">
                                                <span className="name-field-title">File Tilte</span>
                                                <div className="edit-file-name">
                                                    <input className="file-name-input" type="text" value={fileNewName}
                                                        onChange={(event) => {
                                                            setFileNewName(event.target.value.replace(/[^\w\s]/gi, ""));
                                                        }} />
                                                    <Image className="save-file-name" src={CheckIcon} alt="save" onClick={() => saveNewFileName(file)} />
                                                </div>
                                            </div>
                                            : <>
                                                <div className="file-details">
                                                    <span className="file-name">{file.fileName}</span>
                                                    {file.uploadProgress < 100 && <>
                                                        <div className="upload-progress">
                                                            <Progress attached='bottom' color='green' progress='percent' size="large" active total={100} value={file.uploadProgress}></Progress>
                                                            <Image src={CrossIcon} alt="delete" onClick={() => handleUploadFileEvents('delete', file)} />
                                                        </div>
                                                        <span>{file.uploadProgress} % Completed</span>
                                                    </>}
                                                    {file.uploadProgress == 100 &&
                                                        <div>
                                                            {file.sizeExceeded ?
                                                                <span className="file-other-details upload-error">{file.sizeError}</span>
                                                                : <span className="file-other-details">{file.fileSize} MB . {file.uploadDate}</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {file.uploadProgress == 100 && <>
                                                    {file.sizeExceeded ?
                                                        <Image src={ReloadIcon} alt="reload" onClick={() => handleUploadFileEvents('reload', file)} />
                                                        : <>
                                                            {file.downloadLink && <a href={file.downloadLink} download target="_blank" rel="noopener noreferrer"><Image src={DownloadIcon} alt="download" /></a>}
                                                            <Image src={EditIcon} alt="edit" onClick={() => {
                                                                setSelectedFileId(file.fileId);
                                                                setFileNameEditFieldVisible(true);
                                                                setFileNewName(file.fileName.slice(0, file.fileName.lastIndexOf('.')));
                                                            }} />
                                                            <Image src={DeleteIcon} alt="delete" onClick={() => handleUploadFileEvents('delete', file)} />
                                                        </>
                                                    }
                                                </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))}
        </>
    )
}
